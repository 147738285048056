<!--
 * @Description: 热门课程
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-21 17:15:26
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-09-25 18:00:09
-->
<template>
  <div class="section">
    <div class="section-title">
      <div class="main-title">
        <p>热门课程</p>
      </div>
      <div class="sub-title">
        热门黄金班型 满足不同需求 助您顺利过关
      </div>
    </div>
    <div class="section-course">
      <SectionMajor
        :major-list="majorList"
        :more-link="`/course?courseAttribute=${COURSE_ATTRIBUTE_ENUM.PLAN.value}`"
        @changeMajor="changeMajor"
      />
      <div class="course-list">
        <li
          v-for="(item,index) in planData"
          :key="item.planType"
          :class="[setBgClass(item.planType), {active: index === activePlanIndex || planData.length < 3}]"
          @mouseenter="enterPlan(index)"
        >
          <div class="upper">
            <div class="plan-title">
              <div class="plan-name">
                {{ item.planName }}
              </div>
              <div
                v-show="index === activePlanIndex || planData.length < 3"
                class="plan-form"
                :class="[setBgClass(item.planType)]"
              >
                {{ item.planForm }}
              </div>
            </div>

            <!-- 展开时中间显示的内容 -->
            <div
              v-show="index === activePlanIndex || planData.length < 3"
              class="plan-tag"
            >
              <p
                v-for="(tagItem,tagIndex) in item.planTag"
                :key="tagIndex"
              >
                {{ tagItem }}
              </p>
            </div>
            <!-- 折叠时中间显示的内容 -->
            <div v-show="index !== activePlanIndex && planData.length > 2">
              <div
                class="plan-form fold-show"
                :class="[setBgClass(item.planType)]"
              >
                {{ item.planForm }}
              </div>
              <p class="promise">
                {{ item.planPromise }}
              </p>
            </div>
          </div>
          <div
            class="lower"
            :class="{active: index === activePlanIndex || planData.length < 3}"
          >
            <div class="plan-price">
              &yen; <span>{{ item.planPrice }}</span>
            </div>
            <div class="buy-num">
              已购<span>999+</span>人
            </div>
            <div class="lower-button">
              <div
                class="consulting"
                @click="openCustomer"
              >
                咨询
              </div>
              <div
                class="purchase"
                @click="openPlan(index, item.planName)"
              >
                加入学习
              </div>
            </div>
          </div>
        </li>
      </div>
    </div>

    <!-- 选择二级专业弹窗 -->
    <ChildMajorModal
      ref="childMajorRef"
      @checkChildMajor="openPlanDetail"
    />
  </div>
</template>

<script>

import { COURSE_ATTRIBUTE_ENUM } from '@/constant/course';
import SectionMajor from '../section-major';
import { PLAN_TYPE_ENUM, arrData } from './data';
import ChildMajorModal from './child-major-modal';
export default {
  name: 'CourseSection',
  components: {
    SectionMajor,
    ChildMajorModal
  },
  filters: {
  },
  props: {},
  data () {
    return {
      activeMajorIndex: 0,
      majorList: [],
      activePlanIndex: 0,
      planData: [],
      childMajor: null,
      COURSE_ATTRIBUTE_ENUM
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    // 获取专业列表
    this.majorList = arrData.map((item) => {
      return {
        majorName: item.majorName,
        majorId: item.majorId
      };
    });
    // 获取第一个专业下的计划列表
    this.planData = arrData[0].planData;
    // 获取第一个专业下的二级专业列表
    this.childMajor = arrData[0].childMajor || null;
  },
  methods: {

    // 选择专业改变
    changeMajor (data) {
      this.activeMajorIndex = data.index;
      this.activePlanIndex = 0;
      this.planData = arrData[data.index].planData;
      this.childMajor = arrData[data.index].childMajor || null;
    },

    // 点击加入学习
    openPlan (planIndex, planName) {
      if (this.childMajor) {
        this.$refs.childMajorRef.showModal(this.childMajor, planIndex, planName);
        return;
      }
      const coursePlanId = this.planData[planIndex].planId;
      this.openPlanDetail(coursePlanId);
    },

    // 跳转计划详情
    openPlanDetail (coursePlanId) {
      this.$router.push({
        path: '/course/detail',
        query: {
          courseAttribute: COURSE_ATTRIBUTE_ENUM.PLAN.value,
          courseId: coursePlanId
        }
      });
    },

    // 点击咨询
    openCustomer () {
      const qimo = document.getElementById('qimo');
      qimo.click();
    },

    // 设置不同计划的class
    setBgClass (value) {
      switch (value) {
        case PLAN_TYPE_ENUM.XUNMENG.value:
          return 'xunmeng';
        case PLAN_TYPE_ENUM.ZHUIMENG.value:
          return 'zhuimeng';
        case PLAN_TYPE_ENUM.YUANMENG_A.value:
          return 'yuanmeng-a';
        case PLAN_TYPE_ENUM.YUANMENG_B.value:
          return 'yuanmeng-b';
      }
    },

    // 鼠标移入计划
    enterPlan (index) {
      if (index != this.activePlanIndex) {
        this.activePlanIndex = index;
      }
    }

  }
};
</script>
<style lang='less' scoped>
@import "../section.less";
@import "./index.less";
</style>
